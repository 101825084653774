define('Kalkhoff_Translation/js/mage-translation-dictionary',[
    'jquery',
    'jquery/jquery-storageapi',
    'text!js-translation.json',
], function ($, storage, dict) {
    'use strict';

    let translationDict = $.localStorage.get('mage-translation-storage');
    if (typeof translationDict === 'string' && translationDict.length > 0) {
        return JSON.parse(translationDict);
    }

    return {};
});

